<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">
      用户组管理 <span class="text-subtitle-2">{{ item.name }}</span>
    </div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-tabs class="elevation-1">
      <v-tabs-slider></v-tabs-slider>
      <v-tab :href="`#tab-1`">组成员管理</v-tab>
      <v-tab :href="`#tab-2`">组权限配置</v-tab>

      <!-- 用户组基本信息，及成员管理卡片。 -->
      <v-tab-item key="1" value="tab-1">
        <v-card min-height="700">
          <v-container>
            <v-row dense class="mt-2">
              <v-text-field
                v-model="item.name"
                dense
                hide-details
                label="用户组名称"
                outlined
              />
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row dense>
              <v-col cols="12" md="2">
                <h2 class="title-6 text-center">用户组成员:</h2>
              </v-col>
              <v-col cols="12" md="10">
                <v-responsive height="5vh" class="overflow-y-auto">
                  <h2 class="title-6" style="line-height:18px">
	                  {{ group_users }}
                  </h2>
                </v-responsive>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-responsive height="55vh" class="overflow-y-auto">
              <v-row dense>
                <v-col
                  v-for="user in userList"
                  :key="user.id"
                  cols="12"
                  md="2"
                  sm="3"
                  class="pa-1"
                >
                  <v-checkbox
                    v-model="group_users"
                    :label="user.username"
                    :value="user.username"
                    dense
                    hide-details
                    class="mt-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-responsive>
          </v-container>
        </v-card>
      </v-tab-item>

      <!-- 用户组权限分配卡片。 -->
      <v-tab-item key="2" value="tab-2">
        <v-card flat min-height="700" tile>
          <v-row dense class="mt-2">
            <v-col cols="12" md="2">
              <h2 class="title-6 text-center">已选权限：</h2>
            </v-col>
            <v-col cols="12" md="10">
              <v-responsive height="5vh" class="overflow-y-auto">
	              <h2 class="title-6" style="line-height:18px">
		              已选权限： {{ group_permissions }}
	              </h2>
              </v-responsive>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-simple-table height="65vh" fixed-header>
            <thead>
              <tr>
                <th class="text-left" style="width:60%">权限名称</th>
                <th class="text-left" style="width:10%">可见</th>
                <th class="text-left" style="width:10%">新增</th>
                <th class="text-left" style="width:10%">编辑</th>
                <th class="text-left" style="width:10%">删除</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in permissionList"
                :key="index"
                :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-checkbox
                    v-if="item.view"
                    :disabled="checkbox_disabled"
                    :value="item.view"
                    v-model="group_permissions"
                    dense
                    hide-details
                    class="mt-n1"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-if="item.add"
                    :disabled="checkbox_disabled"
                    :value="item.add"
                    v-model="group_permissions"
                    dense
                    hide-details
                    class="mt-n1"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-if="item.change"
                    :disabled="checkbox_disabled"
                    :value="item.change"
                    v-model="group_permissions"
                    dense
                    hide-details
                    class="mt-n1"
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-if="item.delete"
                    :disabled="checkbox_disabled"
                    :value="item.delete"
                    v-model="group_permissions"
                    dense
                    hide-details
                    class="mt-n1"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-row class="mt-2" dense>
      <v-col cols="12" class="text-center"
        ><v-btn color="primary" @click="saveGroup()">确定修改</v-btn></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import { getGroupDetail, updateGroup } from "@/api/usergroups";
import { getUserList } from "@/api/users";
import { getPermissionList } from "@/api/permissions";

export default {
  name: "addGroup",
  props: {
    id: [String, Number],
  },
  data: () => ({
	  item: {
		  name: "Loading...",
	  },
	  userList: [],
	  permissionList: {},
	  group_users: [],
	  models: {},
	  group_permissions: [],
	  default_permissions: [40, 44, 4, 272, 268, 264, 260, 248, 244, 252, 256, 32, 28, 36, 52, 325, 326, 328, 236, 96, 320, 85, 86, 88, 97, 98, 100, 297, 298, 300, 285, 286, 288, 292, 316, 12, 8, 296, 16, 321, 322, 324, 68, 84, 80, 76, 24, 20, 220, 284, 280, 276, 228, 224, 216, 140, 200, 212, 208, 204, 164, 168, 169, 170, 172, 160, 128, 120, 116, 188, 240, 192, 196, 184, 180, 304, 308, 312, 48, 56, 60, 64, 72, 92, 104, 108, 112, 124, 132, 136, 144, 148, 152, 156, 176, 232, 332, 329, 330]
  }),
  computed: {
    checkbox_disabled() {
      return this.item.name.toLowerCase() === "admins";
    },
  },
  async mounted() {
    await this.doGet();
  },
  methods: {
    async doGet() {
      // chenxi 20200916
      // 通过用户组 Id, 获取用户组的信息，包括名称，组成员，权限列表。
      await getGroupDetail(this.id).then((result) => {
	      this.item = result;
	      this.group_users = result.user_set;
	      this.group_permissions = JSON.parse(JSON.stringify(this.default_permissions))
	      result.permissions.map((item) => {
		      if (!this.default_permissions.includes(item.id)) {
			      this.group_permissions.push(item.id);
		      }
	      });
	      if (this.group_permissions !== this.default_permissions) {
		      this.saveGroup()
	      }
      });

      // chenxi 20200916
      // 获取所有用户列表。用于将用户 加入/移除 用户组。 由于 DRF 后端默认的 pagesize = 10,
      //  为了确保一次拿到所有用户，将 pagesize 设置为 10000
      await getUserList({ pagesize: 10000 }).then((result) => {
        this.userList = result.results;
      });

      // chenxi 20200916
      // 获取所有 Model 的列表，用于为用户组分配权限。
      await getPermissionList({ pagesize: 10000 }).then((result) => {
        console.log("PermissionList", result);
        let list = result.results;
        let obj = {};
        list.map((item) => {
          let permission_name = item.codename.split("_")[1];
          let permission_type = item.codename.split("_")[0];
          let name = item.name
            .replace("Can ", "")
            .replace(permission_type + " ", "");
          if (!obj[permission_name]) {
            obj[permission_name] = { name: name };
            obj[permission_name][permission_type] = item.id;
          } else {
            obj[permission_name][permission_type] = item.id;
          }
        });
        this.permissionList = obj;
        console.log("obj", obj);
      });
    },
    permissionClear() {
      this.group_permissions = [];
    },

    saveGroup() {
      let data = {
        id: this.id,
        name: this.item.name,
        user_set: this.group_users,
        permissions: this.group_permissions,
      };
      // console.log("submit", data);
      updateGroup(data).then((result) => {
        console.log("saveGroup()", result);
      });
    },
  },
};
</script>
<style lang="stylus">
.n-title
	font-size 32px

.checkbox >>> .v-label
	font-size 12px
	color #212529
	margin-left -10px !important
	margin-right 10px !important

.pagination
	float: right
</style>
